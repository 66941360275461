import { useNavigate } from "react-router-dom";
import OrderCard from "components/OrderCard";
import { propTypes, defaultProps } from "pages/orders/types";
import { ORDER } from "constants/routes";
import { OrdersGrid } from "styles/pages/orders";

const Orders = ({ state, refresh, updateOrder }) => {
  const navigate = useNavigate();
  return (
    <OrdersGrid>
      {state?.orders?.data?.map((o) => (
        <OrderCard
          key={o?.orderNumber}
          data={o}
          updateOrder={updateOrder}
          handleClick={() =>
            navigate(`${ORDER}/${o?.orderNumber}/${o?.vendorName}`)
          }
          refresh={refresh}
        />
      ))}
    </OrdersGrid>
  );
};

Orders.propTypes = propTypes;
Orders.defaultProps = defaultProps;

export default Orders;
