import { useState } from "react";
import { Button, notification } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Input from "components/Input";
import InputPassword from "components/InputPassword";
import SignUpModal from "components/SignUpModal";
import { usePost } from "hooks/usePost";
import { setUser } from "features/user/userSlice";
import { USERS_SIGN_IN, CRYPTO_KEY } from "constants/services";
import { ORDERS } from "constants/routes";
import Logo from "assets/images/logo.png";
import {
  Wrapper,
  StyledForm,
  Footer,
  StyledLogo,
  Title,
  SubTitle,
  Options,
  Option,
} from "styles/pages/login";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [signUpModal, setSignUpModal] = useState(false);

  return (
    <Wrapper>
      {contextHolder}
      <StyledLogo alt="logo" src={Logo} />
      <Title>Sign in</Title>
      <SubTitle>to continue to BOT Network</SubTitle>
      <StyledForm>
        <Input
          value={email}
          label="Email"
          onChange={(e) => setEmail(e?.target?.value)}
        />
        <InputPassword
          value={password}
          label="Password"
          type="password"
          onChange={(e) => setPassword(e?.target?.value)}
        />

        <Options>
          <Option cursor="no-drop">Forgot Password?</Option>
        </Options>

        <Footer>
          <Button
            className="login"
            type="primary"
            disabled={!email?.trim() || !password}
            onClick={async () => {
              const payload = {
                email,
                password: CryptoJS.AES.encrypt(password, CRYPTO_KEY).toString(),
              };
              const { data } = await mutateAsync({
                url: USERS_SIGN_IN,
                payload,
              });
              if (data?.authToken) {
                dispatch(setUser(data));
                navigate(ORDERS, { replace: true });
              } else {
                api.error({
                  message: "Error",
                  description: data?.message,
                  duration: 5000,
                });
              }
            }}
          >
            Login
          </Button>

          <Button type="primary" onClick={() => setSignUpModal(true)}>
            Sign Up
          </Button>
        </Footer>
      </StyledForm>
      {signUpModal && (
        <SignUpModal
          open={signUpModal}
          onOk={({ message }) => {
            setSignUpModal(false);
            api.success({
              message: "Success",
              description: message,
              duration: 5000,
            });
          }}
          onCancel={() => setSignUpModal(false)}
        />
      )}
    </Wrapper>
  );
};

export default Form;
