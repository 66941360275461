import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import { USER_DECORATIONS } from "constants/services";
import { PRODUCTION_SETTINGS } from "constants/routes";
import { StyledAlert } from "styles/pages/orders";

const UserAlerts = () => {
  const [decorations, setDecorations] = useState();

  const { refetch: refetchDecorations } = useFetch(
    USER_DECORATIONS,
    USER_DECORATIONS,
    {
      enabled: false,
    }
  );

  const fetchDecorations = async () => {
    const { data } = await refetchDecorations();
    setDecorations(data);
  };

  useEffect(() => {
    if (!decorations) fetchDecorations();
  }, [decorations]);

  const checkedDecorations = decorations?.filter((d) => d?.checked);
  const decorationChecked = checkedDecorations?.length > 0;

  const isScreenPrintCheckedAndScreensNotAvailable = checkedDecorations?.find(
    (d) => d?.decoFullName === "Screen Print" && !d?.numberOfScreens
  );

  // const embThreadColorsNotSelected = checkedDecorations?.find(
  //   (d) => d?.decoFullName === "Embroidery" && !d?.threadColors?.length
  // );

  const getMessage = () => {
    if (isScreenPrintCheckedAndScreensNotAvailable) {
      return (
        <div>
          Please provide number of Screens available for Screen Printing
          in&nbsp;
          <Link to={PRODUCTION_SETTINGS}>
            <b>Production Settings</b>
          </Link>
        </div>
      );
    }

    if (!decorationChecked) {
      return (
        <div>
          Please select the decoration method you provide in&nbsp;
          <Link to={PRODUCTION_SETTINGS}>
            <b>Production Settings</b>
          </Link>
        </div>
      );
    }

    // if (embThreadColorsNotSelected) {
    //   return (
    //     <div>
    //       Please provide available Thread Colors for Embroidery in&nbsp;
    //       <Link to={PRODUCTION_SETTINGS}>
    //         <b>Production Settings</b>
    //       </Link>
    //       .
    //     </div>
    //   );
    // }

    return null;
  };

  const getMessageType = () => {
    if (!decorationChecked) return "error";

    // if (
    //   embThreadColorsNotSelected ||
    //   isScreenPrintCheckedAndScreensNotAvailable
    // )
    //   return "warning";

    if (isScreenPrintCheckedAndScreensNotAvailable) return "warning";

    return "success";
  };

  const message = getMessage();
  const type = getMessageType();

  if (!decorations?.length || !message) return null;

  return <StyledAlert message={message} type={type} showIcon />;
};

export default UserAlerts;
