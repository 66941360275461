import PropTypes from "prop-types";

export const propTypes = {
  state: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  }),
  setState: PropTypes.func,
};

export const defaultProps = {
  state: { countries: [] },
  setState: () => {},
};
