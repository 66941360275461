import PropTypes from "prop-types";

export const propTypes = {
  state: PropTypes.shape({
    orders: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) }),
  }),
};

export const defaultProps = {
  state: { orders: { data: [] } },
};
