export const completed = { title: "Completed", value: "Completed" };
export const shipped = { title: "Shipped", value: "Shipped" };
export const inProduction = { title: "In-Production", value: "In-production" };
export const ready = { title: "Pre-Flight", value: "Ready" };
export const cancelled = { title: "Cancelled", value: "Cancelled" };

export const TABS_TITLES = [ready, inProduction, completed, shipped, cancelled];

export const ORDER_STATUS = [
  ready.value,
  inProduction.value,
  completed.value,
  shipped.value,
  cancelled.value,
];
