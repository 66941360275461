import PropTypes from "prop-types";
import Header from "components/Header";
import { Container, Body } from "styles/components/private-layout";

const PrivateLayout = ({ children }) => (
  <Container>
    <Header />
    <Body>{children}</Body>
  </Container>
);

PrivateLayout.propTypes = {
  children: PropTypes.element,
};

PrivateLayout.defaultProps = {
  children: null,
};

export default PrivateLayout;
