import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .ant-picker {
    width: 100%;
  }
`;

export const Label = styled.h3`
  color: #333333;
  margin: 0 0 4px;
  font-size: 14px;
`;
