import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "components/PageTitle";
import ProfileImage from "pages/account-settings/ProfileImage";
import Form from "pages/account-settings/Form";
import { usePost } from "hooks/usePost";
import Events from "pages/account-settings/Events";
import { CRYPTO_KEY, USERS } from "constants/services";
import { setUser } from "features/user/userSlice";
import { Container, Wrapper } from "styles/pages/account-settings";

const AccountSettingsPage = () => {
  const { mutateAsync } = usePost();
  const dispatch = useDispatch();
  const user = useSelector((s) => s?.user);
  const [state, setState] = useState();

  const handleStateChange = (key, value) =>
    setState({ ...state, [key]: value });

  useEffect(() => {
    if (!state && !!user)
      setState({
        ...user,
        password: CryptoJS.AES.decrypt(user?.password, CRYPTO_KEY).toString(
          CryptoJS.enc.Utf8
        ),
      });
  }, [state, user]);

  const updateUserInformation = async (payload) => {
    const { data } = await mutateAsync({
      url: USERS,
      payload,
      isPut: true,
    });

    if (data?.success) {
      dispatch(setUser({ ...data, authToken: user?.authToken }));
      setState({
        ...data,
        password: CryptoJS.AES.decrypt(data?.password, CRYPTO_KEY).toString(
          CryptoJS.enc.Utf8
        ),
      });
    }
  };

  return (
    <Container>
      <PageTitle title="Account Settings" />
      <Wrapper>
        <Form
          state={state}
          handleStateChange={handleStateChange}
          updateUserInformation={updateUserInformation}
        />
        <ProfileImage
          state={state}
          updateUserInformation={updateUserInformation}
        />
      </Wrapper>
      <Events state={state} />
    </Container>
  );
};

export default AccountSettingsPage;
