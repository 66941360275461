import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "components/PrivateLayout";
import { LOGIN } from "constants/routes";

const PrvateRoute = () => {
  const user = useSelector((state) => state?.user);

  return user?.authToken ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={LOGIN} replace />
  );
};

export default PrvateRoute;
