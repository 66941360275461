import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid #999999;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  margin: 0;
`;
