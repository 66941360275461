import { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Avatar, Pagination } from "antd";
import PageTitle from "components/PageTitle";
import { usePost } from "hooks/usePost";
import { EVENTS } from "constants/services";
import { propTypes, defaultProps } from "pages/account-settings/types";
import {
  EventsWrapper,
  StyledEvents,
  StyledTimeline,
  StyledTimelineItem,
  StyledEvent,
  EventInfo,
  EventTime,
  StyledEventName,
  StyledEventDes,
} from "styles/pages/account-settings";

const take = 10;

const Events = ({ state }) => {
  const user = useSelector((s) => s?.user);
  const { mutateAsync } = usePost();
  const [events, setEvents] = useState();
  const [page, setPage] = useState(1);

  const fetchEvents = async () => {
    const { data: response } = await mutateAsync({
      url: EVENTS,
      payload: { take, skip: (page - 1) * take },
    });
    setEvents(response);
  };

  useEffect(() => {
    fetchEvents();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchEvents();
    } else {
      setPage(1);
    }
  }, [user]);

  return (
    <EventsWrapper>
      <PageTitle
        title="Events History"
        rightElement={
          !!events && (
            <Pagination
              total={Math.ceil(events.totalCount)}
              page={page}
              pageSize={take}
              onChange={(v) => setPage(v)}
              showSizeChanger={false}
            />
          )
        }
      />

      <StyledEvents>
        <StyledTimeline>
          {events?.data?.map((e) => {
            const os = e?.device?.os;
            const client = e?.device?.client;
            const device = e?.device?.device;

            return (
              <StyledTimelineItem
                key={e.timeStamp}
                dot={<Avatar src={state?.profileImg} size="large" />}
              >
                <StyledEvent>
                  <EventInfo>
                    <StyledEventName>{e?.eventName}</StyledEventName>
                    <StyledEventDes>
                      {!!os?.name && (
                        <div>
                          {os?.name}&nbsp;({os?.version})
                        </div>
                      )}
                      {!!client?.name && (
                        <div>
                          {client?.name}&nbsp;({client?.version})
                        </div>
                      )}
                      {!!device?.brand && (
                        <div>
                          {device?.brand}&nbsp;({device?.type})
                        </div>
                      )}
                    </StyledEventDes>
                  </EventInfo>
                  <EventTime>
                    {moment.unix(e.timeStamp).format("MMM DD,YYYY | hh:mm A")}
                  </EventTime>
                </StyledEvent>
              </StyledTimelineItem>
            );
          })}
        </StyledTimeline>
      </StyledEvents>
    </EventsWrapper>
  );
};

Events.propTypes = propTypes;

Events.defaultProps = defaultProps;

export default Events;
