import { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import moment from "moment";
import DatePicker from "components/DatePicker";
import Textarea from "components/Textarea";
import Input from "components/Input";
import { OrderShipped } from "styles/components/order-card";

const initialState = {
  trackingNumber: "",
  shippedDate: null,
  notifyClients: true,
};

const OrderMarkShippedModal = ({
  open,
  onOk,
  onCancel,
  enablePartialShippingMessage,
}) => {
  const [state, setState] = useState(initialState);
  const disabled =
    !state?.trackingNumber?.trim() ||
    !state?.shippedDate ||
    (enablePartialShippingMessage
      ? !state?.partialShippingMessage?.trim()
      : false);

  return (
    <Modal
      title="Order Shipped"
      open={open}
      onOk={() =>
        onOk({
          ...state,
          status: enablePartialShippingMessage ? "In-production" : "Shipped",
        })
      }
      onCancel={onCancel}
      okButtonProps={{ disabled }}
      okText="Save"
    >
      <OrderShipped>
        <DatePicker
          label="Shipped Date"
          defaultValue={
            state?.shippedDate ? moment.unix(state?.shippedDate) : null
          }
          disabledDate={(current) =>
            moment().add(-1, "days") >= current ||
            moment().add(1, "month") <= current
          }
          format="DD/MM/YYYY"
          onChange={(_, dateString) => {
            setState({
              ...state,
              shippedDate: moment(dateString, "DD/MM/YYYY").unix(),
            });
          }}
        />

        <Input
          label="Tracking Number"
          value={state?.trackingNumber}
          onChange={(e) =>
            setState({ ...state, trackingNumber: e?.target?.value })
          }
        />

        {enablePartialShippingMessage && (
          <Textarea
            label="Partial Shipping Message"
            rows={5}
            value={state?.partialShippingMessage}
            onChange={(e) =>
              setState({ ...state, partialShippingMessage: e?.target?.value })
            }
          />
        )}
      </OrderShipped>
    </Modal>
  );
};

OrderMarkShippedModal.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  enablePartialShippingMessage: PropTypes.bool,
};

OrderMarkShippedModal.defaultProps = {
  open: false,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  enablePartialShippingMessage: false,
};

export default OrderMarkShippedModal;
