import { useEffect, useState } from "react";
import { Button, Empty } from "antd";
import Comments from "components/OrderCard/CommentsDialog/Comments";
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import { COMMENTS } from "constants/services";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { Wrapper, Title } from "styles/pages/order";
import {
  StyledAddCommentRow,
  CommentsWrapper,
} from "styles/components/order-card";

const Notes = ({ state }) => {
  const { mutateAsync } = usePost();
  const [comments, setComments] = useState();
  const [comment, setComment] = useState();

  const { refetch: refetchComments } = useFetch(
    COMMENTS,
    `${COMMENTS}/${state?.vendorName}/${state?.orderNumber}`,
    {
      enabled: false,
    }
  );

  const fetchComments = async () => {
    const { data } = await refetchComments();
    if (data) {
      setComments(data);
    }
  };

  const addComent = async () => {
    const { data } = await mutateAsync({
      url: `${COMMENTS}/${state?.vendorName}/${state?.orderNumber}`,
      payload: { comment },
    });

    if (data) {
      setComments(data);
      setComment("");
    }
  };

  useEffect(() => {
    if (!comments && state?.orderNumber && state?.vendorName) fetchComments();
  }, [state]);

  return (
    <Wrapper>
      <Title>Support / Comments</Title>
      {comments?.length > 0 ? (
        <CommentsWrapper>
          <Comments comments={comments} />
        </CommentsWrapper>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <StyledAddCommentRow>
        <textarea
          placeholder="Comment"
          minLength={7}
          value={comment}
          onKeyDown={(e) => {
            if (e?.key === "Enter" && !!comment) {
              addComent();
            }
          }}
          onChange={(e) => setComment(e?.target?.value)}
        />
        <Button type="primary" disabled={!comment?.trim()} onClick={addComent}>
          Submit
        </Button>
      </StyledAddCommentRow>
    </Wrapper>
  );
};

Notes.propTypes = PROP_TYPES;

Notes.defaultProps = DEFAULT_STATE;

export default Notes;
