import { Tabs as AntdTabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "features/orders/ordersTabsSlice";
import { propTypes, defaultProps } from "pages/orders/types";
import { TABS_TITLES } from "constants/orders";

const Tabs = ({ state }) => {
  const dispatch = useDispatch();
  const { selectedTabIndex } = useSelector((s) => s?.ordersTabs);

  return (
    <AntdTabs
      activeKey={selectedTabIndex}
      items={TABS_TITLES?.map((t, index) => ({
        ...t,
        label: t?.title,
        key: index,
        disabled: !!state?.searchOrder,
      }))}
      onChange={(key) => {
        dispatch(setTab({ selectedTabIndex: key, page: 1 }));
      }}
    />
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
