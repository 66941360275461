import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "features/user/userSlice";
import appVersionReducer from "features/appVersion/appVersionSlice";
import ordersTabsReducer from "features/orders/ordersTabsSlice";

export default combineReducers({
  user: userReducer,
  appVersion: appVersionReducer,
  ordersTabs: ordersTabsReducer,
});
