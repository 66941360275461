export const BASE_URL = "https://confluence-bot-app.herokuapp.com";
// export const BASE_URL = "https://c7b8-2607-fea8-999e-b940-fcf3-d219-68d5-a5f0.ngrok.io";
export const CRYPTO_KEY = "122BOTConfluence";
export const COUNTRIES = "/botnetwork/countries";
export const USERS_SIGN_UP = "/botnetwork/users-signup";
export const USERS_SIGN_IN = "/botnetwork/users-signin";
export const APP_VERSION = "/botnetwork/update-app-version";
export const EVENTS = "/botnetwork/users/events";
export const USERS = "/botnetwork/users";
export const ORDERS = "/botnetwork/orders";
export const USER_DECORATIONS = "/botnetwork/user-decorations";
export const COMMENTS = "/botnetwork/orders-decorator-comments";
