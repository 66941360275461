import { useState, useEffect } from "react";
import { Pagination, Input, Empty } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "components/PageTitle";
import Tabs from "pages/orders/Tabs";
import Orders from "pages/orders/Orders";
import UserAlerts from "pages/orders/UserAlerts";
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import { APP_VERSION, ORDERS } from "constants/services";
import { TABS_TITLES } from "constants/orders";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import { setTab } from "features/orders/ordersTabsSlice";
import {
  Container,
  PaginationWrapper,
  StyledFilter,
} from "styles/pages/orders";

const { Search } = Input;

const take = 8;

const OrdersPage = () => {
  const { selectedTabIndex, page } = useSelector((s) => s?.ordersTabs);
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [state, setState] = useState();
  const appVersion = useSelector((s) => s?.appVersion?.version);
  const { refetch } = useFetch(APP_VERSION, APP_VERSION, { enabled: false });

  const compareVersion = async () => {
    const { data } = await refetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    }
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  const fetchOrders = async () => {
    const { data: orders } = await mutateAsync({
      url: ORDERS,
      payload: {
        take,
        skip: (page - 1) * take,
        status: state?.searchOrder
          ? null
          : TABS_TITLES[selectedTabIndex]?.value,
        search: state?.searchOrder || null,
      },
    });
    setState({
      ...state,
      orders,
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [page, selectedTabIndex, state?.quickFilter]);

  const refresh = () => {
    if (page === 1) {
      fetchOrders();
    } else {
      dispatch(setTab({ selectedTabIndex, page: 1 }));
    }
  };

  return (
    <Container>
      <UserAlerts />
      <PageTitle title="Orders" />
      <StyledFilter>
        <Search
          placeholder="Search Order"
          size="large"
          onChange={(e) => {
            const value = e?.target?.value;
            setState({ ...state, searchOrder: value });
          }}
          onSearch={refresh}
        />
      </StyledFilter>
      <Tabs state={state} />
      {state?.orders?.totalCount && (
        <PaginationWrapper>
          <Pagination
            pageSize={take}
            total={Math.ceil(state?.orders?.totalCount)}
            showSizeChanger={false}
            page={page}
            onChange={(v) => dispatch(setTab({ selectedTabIndex, page: v }))}
          />
        </PaginationWrapper>
      )}

      {state?.orders?.totalCount > 0 && (
        <Orders state={state} refresh={refresh} />
      )}

      {!state?.orders?.totalCount && <Empty />}
    </Container>
  );
};

export default OrdersPage;
