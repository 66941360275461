import PropTypes from "prop-types";
import { ORDER_STATUS } from "constants/orders";

export const orderPropTypes = {
  data: PropTypes.shape({
    orderCheckInData: PropTypes.arrayOf(PropTypes.shape({})),
    totalStitchCount: PropTypes.number,
    totalPrintLocations: PropTypes.number,
    flagUrl: PropTypes.string,
    vendorLogo: PropTypes.string,
    decorations: PropTypes.arrayOf(PropTypes.string),
    trackingDetails: PropTypes.arrayOf(
      PropTypes.shape({
        trackingNumber: PropTypes.string,
        shippedDate: PropTypes.string,
      })
    ),
    orderComments: PropTypes.arrayOf(
      PropTypes.shape({ user: PropTypes.string })
    ),
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
    createdDate: PropTypes.number,
    inProductionDate: PropTypes.number,
    shippedDate: PropTypes.number,
    status: PropTypes.oneOf(ORDER_STATUS),
    artProof: PropTypes.shape({
      decision: PropTypes.string,
    }),
    orderEvents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        profileImg: PropTypes.string,
      })
    ),
  }),
  handleClick: PropTypes.func,
  refresh: PropTypes.func,
};

export const orderDefaultProps = {
  data: {},
  handleClick: () => {},
  refresh: () => {},
};
