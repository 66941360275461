import styled from "styled-components";

export const Container = styled.div``;

export const StyledLogoWrapper = styled.div`
  text-align: center;
`;
export const StyledLogo = styled.img`
  width: 15vw;
`;

export const Section = styled.div``;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  border-bottom: 1px solid black;
`;
