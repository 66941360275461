import PropTypes from "prop-types";
import { Container, Label } from "styles/components/Input";

const Textarea = ({ label, ...rest }) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    <textarea {...rest} style={{ width: "100%" }} />
  </Container>
);

Textarea.propTypes = {
  label: PropTypes.string,
};

Textarea.defaultProps = { label: "" };

export default Textarea;
