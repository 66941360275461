import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DecorationSettings from "pages/production-settings/DecorationSettings";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import { USER_DECORATIONS, USERS } from "constants/services";
import { Container } from "styles/pages/production-settings";

const ProductionSettings = () => {
  const user = useSelector((state) => state?.user);
  const [state, setState] = useState();
  const { mutateAsync } = usePost();
  const { refetch: refetchDecorations } = useFetch(
    USER_DECORATIONS,
    USER_DECORATIONS,
    {
      enabled: false,
    }
  );

  const fetchDecorations = async () => {
    const { data } = await refetchDecorations();
    setState({ ...state, decorations: data });
  };

  useEffect(() => {
    if (!state?.decorations) fetchDecorations();
  }, [state?.decorations]);

  return (
    <Container>
      <DecorationSettings
        state={state}
        setState={setState}
        updateDecorations={async () => {
          const payload = {
            decorations: state?.decorations?.filter((d) => d?.checked),
            email: user?.email,
          };

          await mutateAsync({ url: USERS, isPut: true, payload });

          setState({ ...state, decorations: undefined });
        }}
      />
    </Container>
  );
};

export default ProductionSettings;
