import { useEffect } from "react";
import { Spin } from "antd";
import { Route, Routes } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PrivateRoute from "components/PrivateRoute";
import {
  QueryClient,
  QueryClientProvider,
  useIsFetching,
  useIsMutating,
} from "react-query";
import { store, persistor } from "root-store";
import LoginPage from "pages/login";
import OrdersPage from "pages/orders";
import OrderPage from "pages/order";
import SettingsPage from "pages/account-settings";
import ProductionSettingsPage from "pages/production-settings";
import { useFetch } from "hooks/useFetch";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import { AxiosInterceptor } from "services/http";
import {
  LOGIN,
  ORDERS,
  ACCOUNT_SETTINGS,
  PRODUCTION_SETTINGS,
  ORDER,
} from "constants/routes";
import { APP_VERSION } from "constants/services";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Wrapper = () => {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const dispatch = useDispatch();
  const appVersion = useSelector((state) => state?.appVersion?.version);

  const { refetch } = useFetch(APP_VERSION, APP_VERSION, { enabled: false });

  const compareVersion = async () => {
    const { data } = await refetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      await dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    }
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  return (
    <Spin spinning={!!isMutating || !!isFetching}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<OrdersPage />} path={ORDERS} exact />
          <Route element={<SettingsPage />} path={ACCOUNT_SETTINGS} exact />
          <Route
            element={<OrderPage />}
            path={`${ORDER}/:orderNumber/:vendorName`}
          />
          <Route
            element={<ProductionSettingsPage />}
            path={PRODUCTION_SETTINGS}
            exact
          />
        </Route>
        <Route path={LOGIN} element={<LoginPage />} />
      </Routes>
    </Spin>
  );
};

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <AxiosInterceptor>
          <Wrapper />
        </AxiosInterceptor>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
);

export default App;
