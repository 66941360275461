const openCloudinaryWidget = ({
  folder,
  clientAllowedFormats,
  cb,
  multiple = false,
  sources = ["local", "camera", "url"],
}) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "big-oven-tees-inc",
      show_powered_by: false,
      uploadPreset: "tnedst8q",
      sources,
      folder,
      clientAllowedFormats,
      multiple,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        cb(result?.info);
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

export default openCloudinaryWidget;
