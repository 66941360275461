import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { Wrapper, Title, StyledIframe } from "styles/pages/order";

const ArtProof = ({ state }) => (
  <Wrapper>
    <Title>Art Proof</Title>

    <StyledIframe src={state?.artProof?.artProofUrl} title="artproof" />
  </Wrapper>
);

ArtProof.propTypes = PROP_TYPES;

ArtProof.defaultProps = DEFAULT_STATE;

export default ArtProof;
