import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: null,
  profileImg: null,
  password: null,
  role: null,
  roleName: null,
  email: null,
  authToken: null,
  companyName: null,
  companyWebsiteUrl: null,
  address1: null,
  address2: null,
  country: "India",
  state: null,
  city: null,
  zip: null,
  status: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.fullName = action?.payload?.fullName;
      state.profileImg = action?.payload?.profileImg;
      state.password = action?.payload?.password;
      state.role = action?.payload?.role;
      state.roleName = action?.payload?.roleName;
      state.email = action?.payload?.email;
      state.authToken = action?.payload?.authToken;
      state.companyName = action?.payload?.companyName;
      state.companyWebsiteUrl = action?.payload?.companyWebsiteUrl;
      state.address1 = action?.payload?.address1;
      state.address2 = action?.payload?.address2;
      state.country = action?.payload?.country;
      state.state = action?.payload?.state;
      state.city = action?.payload?.city;
      state.zip = action?.payload?.zip;
      state.status = action?.payload?.status;
    },
    logout: (state) => {
      state.fullName = initialState?.fullName;
      state.profileImg = initialState?.profileImg;
      state.password = initialState?.password;
      state.role = initialState?.role;
      state.roleName = initialState?.roleName;
      state.email = initialState?.email;
      state.authToken = initialState?.authToken;
      state.companyName = initialState?.companyName;
      state.companyWebsiteUrl = initialState.companyWebsiteUrl;
      state.address1 = initialState.address1;
      state.address2 = initialState.address2;
      state.country = initialState.country;
      state.state = initialState.state;
      state.city = initialState.city;
      state.zip = initialState.zip;
      state.status = initialState.status;
    },
  },
});

export const { setUser, logout } = userSlice.actions;

export default userSlice.reducer;
