import { Button } from "antd";
import CryptoJS from "crypto-js";
import Input from "components/Input";
import InputPassword from "components/InputPassword";
import { propTypes, defaultProps } from "pages/account-settings/types";
import { CRYPTO_KEY } from "constants/services";
import {
  StyledForm,
  FormFooter,
  StyledFormRow,
  StyledFormColumn,
  FieldRow,
} from "styles/pages/account-settings";

const Form = ({ state, handleStateChange, updateUserInformation }) => {
  const disabled = !state?.password || !state?.fullName?.trim();

  return (
    <StyledForm>
      <StyledFormRow>
        <StyledFormColumn>
          <Input value={state?.companyName} label="Company Name" disabled />
          <Input
            value={state?.companyWebsiteUrl}
            label="Company Website"
            disabled
          />
          <FieldRow>
            <Input value={state?.address1} label="Address 1" disabled />
            <Input value={state?.address2} label="Address 2" disabled />
          </FieldRow>

          <FieldRow>
            <Input value={state?.city} label="City" disabled />
            <Input value={state?.state} label="State" disabled />
          </FieldRow>

          <FieldRow>
            <Input value={state?.zip} label="Zip" disabled />
            <Input value={state?.country} label="Country" disabled />
          </FieldRow>
        </StyledFormColumn>

        <StyledFormColumn>
          <Input value={state?.email} label="Email" disabled />
          <Input
            value={state?.fullName}
            label="Full Name"
            onChange={(e) => {
              handleStateChange("fullName", e?.target?.value);
            }}
          />
          <InputPassword
            value={state?.password}
            label="Password"
            type="password"
            onChange={(e) => handleStateChange("password", e?.target?.value)}
          />
          <Input value={state?.roleName} label="Role" disabled />
          <Input value={state?.status} label="Status" disabled />
        </StyledFormColumn>
      </StyledFormRow>

      <FormFooter>
        <Button
          type="primary"
          disabled={disabled}
          onClick={() =>
            updateUserInformation({
              email: state?.email,
              fullName: state?.fullName,
              password: CryptoJS.AES.encrypt(
                state?.password,
                CRYPTO_KEY
              ).toString(),
              profileImg: state?.profileImg,
            })
          }
        >
          Update
        </Button>
      </FormFooter>
    </StyledForm>
  );
};

Form.propTypes = propTypes;

Form.defaultProps = defaultProps;

export default Form;
