import moment from "moment";
import Input from "components/Input";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { Wrapper, Title, Row } from "styles/pages/order";

const GarmentDetails = ({ state }) => {
  const decoratorData = state?.decoratorData;

  return (
    <Wrapper>
      <div>
        <Title>Garment Detail(s)</Title>
        <Row>
          <Input
            disabled
            label="Garment Sent Date"
            value={
              decoratorData?.garmentSentDate
                ? moment
                    .unix(decoratorData.garmentSentDate)
                    .format("MMM DD, YYYY")
                : "NA"
            }
          />

          <Input
            disabled
            label="Tracking Number"
            value={decoratorData?.trackingNumber || "NA"}
          />

          <Input
            disabled
            label="Order Due Date"
            value={
              decoratorData?.orderDueDate
                ? moment.unix(decoratorData.orderDueDate).format("MMM DD, YYYY")
                : "NA"
            }
          />
        </Row>
      </div>
    </Wrapper>
  );
};

GarmentDetails.propTypes = PROP_TYPES;

GarmentDetails.defaultProps = DEFAULT_STATE;

export default GarmentDetails;
