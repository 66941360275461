import PropTypes from "prop-types";
import { Container, StyledSelect, Label } from "styles/components/select";

const Select = ({ label, ...rest }) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    <StyledSelect {...rest} />
  </Container>
);

Select.propTypes = {
  label: PropTypes.string,
};

Select.defaultProps = { label: "" };

export default Select;
