import CryptoJS from "crypto-js";
import { EditOutlined } from "@ant-design/icons";
import openCloudinaryWidget from "utils/cloudinary";
import { CRYPTO_KEY } from "constants/services";
import { propTypes, defaultProps } from "pages/account-settings/types";
import {
  ProfileImageWrapper,
  StyledProfileImage,
  StyledAvatar,
  StyledEditIcon,
  UserName,
  UserEmail,
} from "styles/pages/account-settings";

const ProfileImage = ({ state, updateUserInformation }) => (
  <ProfileImageWrapper>
    <StyledProfileImage>
      <StyledAvatar src={state?.profileImg} />
      <StyledEditIcon
        icon={<EditOutlined />}
        size="large"
        onClick={() => {
          openCloudinaryWidget({
            folder: `BOTNetwork/Users/${state?.email}/ProfileImage`,
            cb: (v) =>
              updateUserInformation({
                email: state?.email,
                fullName: state?.fullName,
                password: CryptoJS.AES.encrypt(
                  state?.password,
                  CRYPTO_KEY
                ).toString(),
                profileImg: v?.secure_url,
              }),
          });
        }}
      />
    </StyledProfileImage>
    <div>
      <UserName>{state?.fullName}</UserName>
      <UserEmail>{state?.email}</UserEmail>
    </div>
  </ProfileImageWrapper>
);

ProfileImage.propTypes = propTypes;

ProfileImage.defaultProps = defaultProps;

export default ProfileImage;
