import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  version: null,
};

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    setAppVersion: (state, action) => {
      state.version = action?.payload?.version;
    },
  },
});

export const { setAppVersion } = appVersionSlice.actions;

export default appVersionSlice.reducer;
