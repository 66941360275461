import LeftView from "components/Header/LeftView";
import RightView from "components/Header/RightView";
import { Container } from "styles/components/header";

const Header = () => (
  <Container>
    <LeftView />
    <RightView />
  </Container>
);

export default Header;
