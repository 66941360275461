import PropTypes from "prop-types";
import { DatePicker as AntdDatePicker } from "antd";
import { Container, Label } from "styles/components/datepicker";

const DatePicker = ({ label, ...rest }) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    <AntdDatePicker size="large" {...rest} />
  </Container>
);

DatePicker.propTypes = {
  label: PropTypes.string,
};

DatePicker.defaultProps = { label: "" };

export default DatePicker;
