import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Spin, notification } from "antd";
import { useIsFetching, useIsMutating } from "react-query";
import UserInformation from "components/SignUpModal/UserInformation";
import CompanyInformation from "components/SignUpModal/CompanyInformation";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import { COUNTRIES, USERS_SIGN_UP } from "constants/services";
import validateEmail from "utils/validate-email";
import Logo from "assets/images/logo.png";
import {
  Container,
  StyledLogoWrapper,
  StyledLogo,
} from "styles/components/sign-up-modal";

const SignUpModal = ({ open, onOk, onCancel }) => {
  const [api, contextHolder] = notification.useNotification();
  const { mutateAsync } = usePost();
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const [state, setState] = useState();
  const { refetch } = useFetch(COUNTRIES, COUNTRIES, { enabled: false });

  const handleStateChange = (key, value) =>
    setState({ ...state, [key]: value });

  const fetchCountries = async () => {
    const { data: countries } = await refetch();
    handleStateChange("countries", countries);
  };

  useEffect(() => {
    if (!state?.countries) fetchCountries();
  }, [state?.countries]);

  const handleOk = async () => {
    const {
      name,
      email,
      companyName,
      companyWebsiteUrl,
      address1,
      address2,
      country,
      city,
      zip,
      phoneNumber,
      taxNumber,
    } = state;
    const payload = {
      name,
      email,
      companyName,
      companyWebsiteUrl,
      address1,
      address2,
      country,
      state: state?.state,
      city,
      zip,
      phoneNumber,
      taxNumber,
    };
    const { data } = await mutateAsync({ url: USERS_SIGN_UP, payload });
    if (data?.success) {
      onOk({ message: data?.message });
    } else {
      api.error({
        message: "Error",
        description: data?.message,
      });
    }
  };

  const disabled =
    !state?.name?.trim() ||
    !state?.email?.trim() ||
    !validateEmail(state?.email) ||
    !state?.companyName?.trim() ||
    !state?.address1?.trim() ||
    !state?.country?.trim() ||
    !state?.city?.trim() ||
    !state?.zip?.trim() ||
    !state?.phoneNumber ||
    !state?.taxNumber?.trim();

  return (
    <Modal
      title="Sign Up to BOT Network"
      centered
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      width={1000}
      okText="Sign Up"
      okButtonProps={{
        disabled,
        style: {
          backgroundColor: disabled ? "lightgrey" : "red",
          color: "#fff",
          fontWeight: "bold",
        },
      }}
      cancelButtonProps={{
        style: {
          fontWeight: "bold",
        },
      }}
    >
      <Spin spinning={!!isMutating || !!isFetching}>
        {contextHolder}
        <Container>
          <StyledLogoWrapper>
            <StyledLogo src={Logo} alt="logo" />
          </StyledLogoWrapper>

          <UserInformation
            state={state}
            handleStateChange={handleStateChange}
          />

          <CompanyInformation
            state={state}
            setState={setState}
            handleStateChange={handleStateChange}
          />
        </Container>
      </Spin>
    </Modal>
  );
};

SignUpModal.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

SignUpModal.defaultProps = {
  open: false,
  onOk: () => {},
  onCancel: () => {},
};

export default SignUpModal;
