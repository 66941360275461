import { useQuery } from "react-query";
import HttpService from "services/http";

export const fetcher = async (url) => {
  try {
    const response = await HttpService.get(url);
    return response?.data;
  } catch (error) {
    return { error: error?.message };
  }
};

export const useFetch = (key, url, options) =>
  useQuery([key, url], () => fetcher(url), options);
