import PropTypes from "prop-types";

const PROP_TYPES = {
  state: PropTypes.shape({
    orderEstimationDialog: PropTypes.bool,
    notifyClients: PropTypes.bool,
    poSecureUrl: PropTypes.string,
    inProductionDate: PropTypes.number,
    requestedShipDate: PropTypes.number,
    requestedInHandDate: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    clientEmails: PropTypes.arrayOf(PropTypes.shape({})),
    assigneeEmails: PropTypes.arrayOf(PropTypes.shape({})),
    vendorName: PropTypes.string,
    vendors: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    vendorNameInput: PropTypes.string,
    poNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vendor: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  setState: PropTypes.func,
  createOrder: PropTypes.func,
  updateOrderStatus: PropTypes.func,
  quickBooksConnect: PropTypes.func,
  generatePdf: PropTypes.func,
};

const DEFAULT_STATE = {
  state: {
    orderEstimationDialog: false,
    notifyClients: false,
    inProductionDate: null,
    requestedShipDate: null,
    requestedInHandDate: null,
    clientEmail: [],
    poNumber: "",
    vendor: { name: "", id: "", label: "" },
    vendorNameInput: "",
  },
  setState: (v) => v,
  createOrder: () => {},
  updateOrderStatus: () => {},
  quickBooksConnect: () => {},
  generatePdf: () => {},
};

export { PROP_TYPES, DEFAULT_STATE };
