import styled from "styled-components";
import { Card } from "antd";
import { HourglassOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  getBackgroundColorByStatus,
  getChipColorByDates,
  getBackgroundColorByArtProof,
} from "components/OrderCard/utils";

export const Body = styled.div`
  -webkit-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  -moz-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  flex-grow: 1;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  border-left: 2px solid ${({ status }) => getBackgroundColorByStatus(status)};
  transition: transform 0.2s;
`;

export const Container = styled(Card)`
  &.ant-card {
    background-color: transparent;
    border: none;
  }

  .ant-card-body {
    padding: 0;
  }

  :hover {
    ${Body} {
      transform: scale(0.8);
    }
  }
`;

export const VendorLogo = styled.div`
  background-image: url(${(props) => props?.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90px;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px;
  height: 36px;
  align-items: center;
`;

// export const Flag = styled.img`
//   width: 20px;
//   height: 15px;
//   margin-top: 4px;
// `;

export const Comment = styled.div``;

export const StyledHourglassOutlined = styled(HourglassOutlined)`
  cursor: pointer;
  color: #a1a1a1;
  font-size: 20px !important;
`;

export const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  cursor: pointer;
  color: green;
  font-size: 20px !important;
`;

export const Wrapper = styled.div`
  padding: 0 5px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const PONumber = styled.div`
  font-weight: bold;
  color: #5e6c84;
  font-size: 14px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// export const VendorName = styled.div`
//   color: #172b4d;
//   max-width: 110px;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

export const Status = styled.div`
  background-color: ${({ status }) => getBackgroundColorByStatus(status)};
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 12px;
  color: white;
  font-weight: bold;
`;

export const UploadDate = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: #172b4d;
`;

export const InProductionDate = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const ShippedDate = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const DecorationChips = styled.div`
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const DecorationChip = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  background-color: ${(props) => getChipColorByDates(props)};
  color: #fff;
  border-radius: 16px;
  padding: 4px;
  font-weight: bold;
`;

export const ArtProofWrapper = styled.div`
  background-color: ${({ artProof }) => getBackgroundColorByArtProof(artProof)};
  padding: 5px;
  font-size: 12px;
  margin-top: 10px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const MarkAsShipped = styled.div`
  font-size: 13px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 20px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

export const StyledCheckIn = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #000;

  .anticon.success {
    color: green;
    font-size: 14px;
    font-weight: bold;
  }

  .anticon.error {
    color: orange;
    font-size: 14px;
    font-weight: bold;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

export const MessageRow = styled.div`
  display: flex;
`;

export const StyledMessage = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #a8ddfd;
  border: 1px solid #97c6e3;
  border-radius: 10px;
  font-size: 12px;
`;

export const StyledMessageContent = styled.p`
  padding-bottom: 14px;
  margin: 0;
`;

export const MessageTimeStamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  bottom: 3px;
  right: 5px;
`;

export const MessageDisplayName = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
`;

export const MessageBody = styled.div`
  width: 100%;
`;

export const StyledAddCommentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;

  textarea {
    width: 100%;
  }
`;

export const CommentsWrapper = styled.div`
  max-height: 50vh;
  overflow: auto;
  margin-bottom: 20px;
`;

export const OrderShipped = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
