import styled from "styled-components";
import { Avatar } from "antd";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  background-color: #000;
  color: #fff;
  height: 40px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    font-size: 20px;
    cursor: pointer;
  }
  img {
    width: 60px;
  }
`;

export const Right = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

export const StyledMenu = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  padding: 5px 12px;
  width: 100%;
  text-align: left;
`;

export const DrawerLogoWrapper = styled.div`
  text-align: center;
`;

export const StyledLogo = styled.img`
  width: 100px;
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: black;
  font-weight: bold;
  padding: 0 10px;
  height: 50px;
  margin-bottom: 10px;

  &.active,
  :hover {
    background: #000;
    color: #fff;
    border-radius: 10px;
  }
`;

export const MenuIcon = styled.div`
  svg {
    font-size: 22px;
  }
`;

export const StyledFooter = styled.div`
  position: absolute;
  bottom: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 10px;
  right: 0px;
  left: 0px;
  text-align: center;
  p {
    font-size: 1rem;
    margin: 0;
    line-height: 24px;
    font-weight: bold;
  }

  p.role-name {
    color: rgb(161, 161, 161);
    font-weight: normal;
  }
`;
