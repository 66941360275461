import Input from "components/Input";
import { propTypes, defaultProps } from "components/SignUpModal/types";
import { Section, Title, Row } from "styles/components/sign-up-modal";

const UserInformation = ({ state, handleStateChange }) => (
  <Section>
    <Title>User Information</Title>
    <Row>
      <Input
        label="Name"
        value={state?.name}
        onChange={(e) => handleStateChange("name", e?.target?.value)}
      />
      <Input
        label="Email"
        value={state?.email}
        type="email"
        onChange={(e) => handleStateChange("email", e?.target?.value)}
      />
    </Row>
  </Section>
);

UserInformation.propTypes = propTypes;

UserInformation.defaultProps = defaultProps;

export default UserInformation;
