import styled from "styled-components";

export const PageBackground = styled.div`
  background-color: #adadad;
  filter: blur(8px);
  -webkit-filter: blur(5px);
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 40%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
`;
export const StyledLogo = styled.img`
  display: block;
  width: 15vw;
  margin: 0 auto;
`;

export const Title = styled.h1`
  text-align: center;
  margin: 10px 0;
  font-size: 24px;
  font-weight: 600;
`;

export const SubTitle = styled.h2`
  text-align: center;
  margin: 0px;
  font-size: 16px;
  font-weight: normal;
`;

export const StyledForm = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  button {
    background-color: red;
    color: white;
    font-weight: bold;
    :hover {
      background-color: #ee5c5c !important;
      color: white;
    }
  }
  button.login {
    background-color: #1677ff;

    :hover {
      background-color: #1677ff !important;
      color: white;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Option = styled.h3`
  font-size: 15px;
  text-decoration: underline;
  cursor: ${({ cursor }) => cursor};
  color: #58a6ff;
  margin: 0;
`;
