import Notes from "pages/order/Notes";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
// import InProductionDate from "pages/order/InProductionDate";
import CompleteDate from "pages/order/CompleteDate";
import GarmentDetails from "pages/order/GarmentDetails";
import {
  Header,
  Logo,
  Wrapper,
  Title,
  StyledOrderNumber,
} from "styles/pages/order";

const OrderInformation = ({ state, setState, updateOrder }) => {
  // const isInProduction = state?.status === "In-production";
  const isReady = state?.status === "Ready";
  const isCompleted = state?.status === "Completed";
  const isShipped = state?.status === "Shipped";

  return (
    <Header>
      <Wrapper>
        <Title>Order Details</Title>
        <Logo
          src="https://bigoventees.netlify.app/static/media/logo.f7aedac0.png"
          alt="Logo"
        />
        <StyledOrderNumber>{state?.orderNumber}</StyledOrderNumber>
      </Wrapper>

      <div>
        {/* <InProductionDate
          state={state}
          setState={setState}
          updateOrder={updateOrder}
        /> */}

        <GarmentDetails state={state} />

        {(isReady || isCompleted || isShipped) && (
          <CompleteDate
            state={state}
            setState={setState}
            updateOrder={updateOrder}
          />
        )}
      </div>
      <Notes state={state} />
    </Header>
  );
};

OrderInformation.propTypes = PROP_TYPES;

OrderInformation.defaultProps = DEFAULT_STATE;

export default OrderInformation;
