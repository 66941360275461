import { Dropdown } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "features/user/userSlice";
import { ACCOUNT_SETTINGS } from "constants/routes";
import { Right, StyledAvatar, StyledMenu } from "styles/components/header";

const RightView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  return (
    <Right>
      <Dropdown
        arrow
        menu={{
          items: [
            {
              style: { padding: 0 },
              label: (
                <StyledMenu
                  type="button"
                  onClick={() => navigate(ACCOUNT_SETTINGS)}
                >
                  Account Settings
                </StyledMenu>
              ),
              key: 1,
            },
            {
              style: { padding: 0 },
              label: (
                <StyledMenu type="button" onClick={() => dispatch(logout())}>
                  Logout
                </StyledMenu>
              ),
              key: 2,
            },
          ],
        }}
        trigger={["click"]}
      >
        <StyledAvatar src={user?.profileImg} alt="profile" size={40} />
      </Dropdown>
    </Right>
  );
};

export default RightView;
