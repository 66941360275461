import PropTypes from "prop-types";

const decoration = PropTypes.shape({
  decoCode: PropTypes.string,
  decoFullName: PropTypes.string,
  iconUrl: PropTypes.string,
});

export const propTypes = {
  state: PropTypes.shape({
    decorations: PropTypes.arrayOf(decoration),
  }),
};

export const defaultProps = {
  state: { decorations: undefined },
};
