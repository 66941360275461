import PropTypes from "prop-types";
import { Input as AntdInput } from "antd";
import { Container, Label } from "styles/components/Input";

const InputPassword = ({ label, ...rest }) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    <AntdInput.Password size="large" {...rest} />
  </Container>
);

InputPassword.propTypes = {
  label: PropTypes.string,
};

InputPassword.defaultProps = { label: "" };

export default InputPassword;
