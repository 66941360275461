import styled from "styled-components";

export const Container = styled.div``;

export const StyledDecorationSettings = styled.div``;

export const StyledDecorationRow = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-bottom: ${({ showBorder }) =>
    showBorder ? "1px dashed black" : "none"};

  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? "20px" : "0px")};
  margin-bottom: 20px;
`;

export const DecorationIcon = styled.img`
  width: 50px;
  margin-right: 20px;
`;

export const StyledThreadColors = styled.div`
  border-bottom: 1px dashed black;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;
