import PropTypes from "prop-types";
import { Container, Title } from "styles/components/page-title";

const PageTitle = ({ title, rightElement }) => (
  <Container>
    <Title>{title}</Title>
    {!!rightElement && rightElement}
  </Container>
);

PageTitle.propTypes = {
  title: PropTypes.string,
  rightElement: PropTypes.node,
};

PageTitle.defaultProps = {
  title: "",
  rightElement: null,
};

export default PageTitle;
