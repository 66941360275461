import Form from "pages/login/Form";
import { Container, PageBackground } from "styles/pages/login";

const Login = () => (
  <>
    <PageBackground />
    <Container>
      <Form />
    </Container>
  </>
);

export default Login;
