import WorkOrderPdf from "pages/order/WorkOrderPdf";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { Wrapper, Title, StyledPDFViewer } from "styles/pages/order";

const WorkOrder = ({ state }) => (
  <Wrapper>
    <Title>Work Order</Title>

    <StyledPDFViewer>
      <WorkOrderPdf state={state} />
    </StyledPDFViewer>
  </Wrapper>
);

WorkOrder.propTypes = PROP_TYPES;

WorkOrder.defaultProps = DEFAULT_STATE;

export default WorkOrder;
