import {
  SettingOutlined,
  ShoppingOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import {
  ORDERS,
  ACCOUNT_SETTINGS,
  PRODUCTION_SETTINGS,
} from "constants/routes";

const MENUS = [
  {
    title: "Orders",
    Icon: ShoppingOutlined,
    link: ORDERS,
  },
  {
    title: "Production Settings",
    Icon: SlidersOutlined,
    link: PRODUCTION_SETTINGS,
  },
  {
    title: "Account Settings",
    Icon: SettingOutlined,
    link: ACCOUNT_SETTINGS,
  },
];

export default MENUS;
