import styled from "styled-components";
import { Avatar, Timeline } from "antd";

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const StyledFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const StyledProfileImage = styled.div`
  position: relative;
`;

export const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    width: 200px;
    height: 200px;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
`;

export const UserName = styled.h3`
  margin: 0 0 3px;
  text-align: center;
`;

export const UserEmail = styled.h4`
  margin: 0;
  text-align: center;
  font-weight: normal;
`;

export const StyledEditIcon = styled(Avatar)`
  &.ant-avatar {
    position: absolute;
    bottom: 2px;
    right: 15%;
    background: black;
    cursor: pointer;
  }
`;

export const EventsWrapper = styled.div``;

export const StyledEvents = styled.div`
  margin-top: 30px;
`;

export const StyledTimeline = styled(Timeline)``;
export const StyledTimelineItem = styled(Timeline.Item)`
  &.ant-timeline-item {
    padding-bottom: 60px;
  }
`;

export const StyledEvent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`;

export const StyledEventName = styled.h3`
  margin: 0;
`;

export const StyledEventDes = styled.h5`
  margin: 0;
  color: #999999;
`;

export const EventInfo = styled.div``;

export const EventTime = styled.h5`
  margin: 0;
  color: #999999;
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 10px;
`;
