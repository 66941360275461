import { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  // Divider, Space
} from "antd";
import Input from "components/Input";
// import Select from "components/Select";
import PageTitle from "components/PageTitle";
// import { PlusOutlined } from "@ant-design/icons";
import { propTypes, defaultProps } from "pages/production-settings/types";
import {
  StyledDecorationSettings,
  StyledDecorationRow,
  DecorationIcon,
  StyledThreadColors,
} from "styles/pages/production-settings";

const DecorationSettings = ({ state, setState, updateDecorations }) => {
  // const [createValue, setCreateValue] = useState();
  const [threadColors, setThreadColors] = useState([]);
  const disbaled = !!state?.decorations?.filter(
    (d) => d?.checked && !d?.limitPerDay
  )?.length;

  useEffect(() => {
    if (state?.decorations?.length > 0 && !threadColors?.length) {
      const embroideryDecoration = state?.decorations?.find(
        (d) => d?.decoFullName === "Embroidery"
      );

      if (embroideryDecoration?.threadColors?.length > 0) {
        setThreadColors(embroideryDecoration?.threadColors);
      }
    }
  }, [threadColors, state?.decorations]);

  // const dropdownRender = (menu) => (
  //   <>
  //     {menu}
  //     <Divider
  //       style={{
  //         margin: "8px 0",
  //       }}
  //     />
  //     <Space
  //       style={{
  //         padding: "0 8px 4px",
  //       }}
  //     >
  //       <Input
  //         value={createValue}
  //         onChange={(e) => setCreateValue(e?.target?.value)}
  //         onBlur={() =>
  //           setTimeout(() => {
  //             setCreateValue(undefined);
  //           }, 100)
  //         }
  //       />
  //       <Button
  //         type="primary"
  //         icon={<PlusOutlined />}
  //         disabled={!createValue?.trim()}
  //         onClick={async (e) => {
  //           e.preventDefault();

  //           const alreadyExist = threadColors?.find(
  //             (c) => c?.toLowerCase() === createValue?.toLowerCase()
  //           );

  //           if (!alreadyExist) {
  //             setThreadColors([...threadColors, createValue]);
  //           }
  //         }}
  //       >
  //         Add Thread Color
  //       </Button>
  //     </Space>
  //   </>
  // );

  return (
    <>
      <PageTitle
        title="Decoration Settings"
        rightElement={
          <Button
            type="primary"
            disabled={disbaled}
            onClick={updateDecorations}
          >
            Update
          </Button>
        }
      />
      <StyledDecorationSettings>
        {state?.decorations?.map((d, i) => (
          <div key={d?.decoCode}>
            <StyledDecorationRow
              showBorder={
                d?.decoFullName !== "Embroidery" &&
                d?.decoFullName !== "Screen Print"
              }
              paddingBottom={
                d?.decoFullName !== "Embroidery" &&
                d?.decoFullName !== "Screen Print"
              }
            >
              <DecorationIcon src={d?.iconUrl} alt="logo" />
              <Checkbox
                checked={d?.checked}
                onChange={(e) =>
                  setState({
                    ...state,
                    decorations: state?.decorations?.map((f, j) => {
                      if (i !== j) return f;

                      return { ...f, checked: e?.target?.checked };
                    }),
                  })
                }
              >
                {d?.decoFullName}
              </Checkbox>

              <Input
                label="Production Limit Per Day"
                disabled={!d?.checked}
                type="number"
                value={d?.limitPerDay}
                onChange={(e) =>
                  setState({
                    ...state,
                    decorations: state?.decorations?.map((f, j) => {
                      if (i !== j) return f;
                      return { ...f, limitPerDay: Number(e?.target?.value) };
                    }),
                  })
                }
              />
            </StyledDecorationRow>
            {d?.decoFullName === "Screen Print" && (
              <StyledThreadColors>
                <Input
                  type="number"
                  value={d?.numberOfScreens}
                  label="Number of Screen(s) Available"
                  onChange={(e) =>
                    setState({
                      ...state,
                      decorations: state?.decorations?.map((f, j) => {
                        if (i !== j) return f;

                        return {
                          ...f,
                          numberOfScreens: Number(e?.target?.value),
                        };
                      }),
                    })
                  }
                />
              </StyledThreadColors>
            )}

            {/* {d?.decoFullName === "Embroidery" && (
              <StyledThreadColors>
                {d?.decoFullName === "Embroidery" && (
                  <Select
                    size="large"
                    label="Available Thread Color(s)"
                    placeholder="Available Thread Color(s)"
                    value={d?.threadColors}
                    allowClear
                    mode="multiple"
                    showSearch
                    options={
                      threadColors?.map((o) => ({
                        value: o,
                        label: o,
                      })) || []
                    }
                    onChange={(e) =>
                      setState({
                        ...state,
                        decorations: state?.decorations?.map((f, j) => {
                          if (i !== j) return f;

                          return { ...f, threadColors: e };
                        }),
                      })
                    }
                    dropdownRender={(menu) => dropdownRender(menu)}
                  />
                )}
              </StyledThreadColors>
            )} */}
          </div>
        ))}
      </StyledDecorationSettings>
    </>
  );
};

DecorationSettings.propTypes = propTypes;

DecorationSettings.defaultProps = defaultProps;

export default DecorationSettings;
