import { useState } from "react";
import { Tooltip, Avatar } from "antd";
import moment from "moment";
import _ from "lodash";
import { orderPropTypes, orderDefaultProps } from "components/OrderCard/types";
import { getArtProofStatusTitle } from "components/OrderCard/utils";
import OrderMarkShippedModal from "components/OrderCard/OrderMarkShippedModal";
import { usePost } from "hooks/usePost";
import { completed, inProduction, shipped, ready } from "constants/orders";
import { ORDERS } from "constants/services";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
  WarningOutlined,
  CheckSquareOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import {
  Container,
  Body,
  VendorLogo,
  Comment,
  StyledHourglassOutlined,
  StyledCheckCircleOutlined,
  Wrapper,
  Header,
  PONumber,
  Status,
  UploadDate,
  InProductionDate,
  ShippedDate,
  DecorationChips,
  DecorationChip,
  ArtProofWrapper,
  MarkAsShipped,
  StyledCheckIn,
} from "styles/components/order-card";

const OrderCard = ({ data, handleClick, refresh }) => {
  const { mutateAsync } = usePost();
  const [openMarkShippedModal, setOpenMarkShippedModal] = useState(false);
  const isOrderCompleted = data?.status === completed?.value;
  const isOrderInProduction = data?.status === inProduction?.value;
  const isOrderInPreProd = data?.status === ready?.value;
  const orderCheckInData = data?.orderCheckInData;
  const isCompletedStockStatus = !!orderCheckInData?.find(
    (o) => o?.stockStatus === "Complete"
  );

  const updateOrder = async (payload) => {
    await mutateAsync({
      url: ORDERS,
      isPatch: true,
      payload,
    });
    refresh();
  };

  return (
    <Container>
      <Body
        status={data?.status}
        artproof={!!data?.artProof}
        onClick={handleClick}
      >
        <VendorLogo src={data?.vendorLogo} onClick={(e) => e.stopPropagation()}>
          <Comment>
            <Tooltip
              title={
                data?.paymentDetails
                  ? `Payment Completed : $${data?.paymentDetails?.paymentAmount} USD`
                  : "Payment Pending"
              }
              arrow
              placement="bottom"
            >
              {data?.paymentDetails ? (
                <StyledCheckCircleOutlined />
              ) : (
                <StyledHourglassOutlined />
              )}
            </Tooltip>
          </Comment>
        </VendorLogo>

        <Wrapper>
          <Header>
            <PONumber>{data?.orderNumber}</PONumber>
            <Tooltip title={data?.orderEvents[0]?.name} arrow>
              <Avatar
                src={data?.orderEvents[0]?.profileImg}
                sx={{
                  bgcolor: "#a1a1a1",
                  width: 32,
                  height: 32,
                  fontSize: 12,
                  border: "1px solid #a1a1a1",
                }}
              />
            </Tooltip>
          </Header>

          <Status status={data?.status}>{data?.status}</Status>

          <UploadDate>
            Created Date :&nbsp;
            {moment.unix(data?.createdDate).format("MMM DD,YYYY")}
          </UploadDate>
          {(data?.status === inProduction.value ||
            data?.status === completed.value ||
            data?.status === shipped.value) &&
            data?.inProductionDate && (
              <InProductionDate>
                Production Date :&nbsp;
                {moment.unix(data?.inProductionDate).format("MMM DD,YYYY")}
              </InProductionDate>
            )}
          {data?.status === shipped.value &&
            !!data?.trackingDetails?.length && (
              <ShippedDate>
                Shipped Date :&nbsp;
                {moment
                  .unix(_.last(data?.trackingDetails)?.shippedDate)
                  .format("MMM DD,YYYY")}
              </ShippedDate>
            )}

          <DecorationChips>
            {data?.decorations?.map((d) => {
              const inProductionDateKey = `${d?.toLowerCase()}InProductionDate`;
              const completedDateKey = `${d?.toLowerCase()}CompletedDate`;
              const inProductionDate = data[inProductionDateKey];
              const completedDate = data[completedDateKey];
              return (
                <Tooltip
                  title={`${
                    inProductionDate || completedDate
                      ? moment
                          .unix(completedDate || inProductionDate)
                          .format("MMM DD, YYYY")
                      : `Not Set`
                  }${
                    d === "SCREENPRINT" && data?.totalPrintLocations
                      ? ` , Print Locations : ${data?.totalPrintLocations}`
                      : ""
                  }${
                    d === "EMB" && data?.totalStitchCount
                      ? `, Stitch Counts : ${data?.totalStitchCount}`
                      : ""
                  }`}
                  key={d}
                  arrow
                >
                  <DecorationChip
                    inProductionDate={inProductionDate}
                    completedDate={completedDate}
                  >
                    {!!completedDate && <CheckCircleOutlined />}

                    {!completedDate && !!inProductionDate && <ReloadOutlined />}

                    {!completedDate && !inProductionDate && (
                      <InfoCircleOutlined />
                    )}
                    {d}
                  </DecorationChip>
                </Tooltip>
              );
            })}
          </DecorationChips>

          {!!data?.trackingDetails?.length && (
            <UploadDate>
              Tracking :&nbsp;
              {data?.trackingDetails?.map((t) => t?.trackingNumber)?.join(", ")}
            </UploadDate>
          )}
        </Wrapper>

        <ArtProofWrapper artProof={data?.artProof}>
          {data?.status !== inProduction.value && <CheckCircleOutlined />}
          {getArtProofStatusTitle(data?.artProof)}
        </ArtProofWrapper>

        {(isOrderCompleted || isOrderInProduction) &&
          data?.shippingLabelInformation && (
            <MarkAsShipped
              onClick={async (e) => {
                e.stopPropagation();
                const p = {
                  orderNumber: data?.orderNumber,
                  vendorName: data?.vendorName,
                  status: "Shipped",
                };
                await updateOrder(p);
                window.open(data?.shippingLabelInformation?.shippingLabelUrl);
              }}
            >
              <CloudDownloadOutlined />
              {isOrderInProduction ? "Partially Ship Label" : "Shipping Label"}
            </MarkAsShipped>
          )}

        {(isOrderInPreProd || isOrderInProduction) && (
          <StyledCheckIn
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isCompletedStockStatus ? (
              <CheckSquareOutlined className="success" />
            ) : (
              <WarningOutlined className="error" />
            )}
            Check-in
          </StyledCheckIn>
        )}
      </Body>

      {openMarkShippedModal && (
        <OrderMarkShippedModal
          open={openMarkShippedModal}
          enablePartialShippingMessage={isOrderInProduction}
          onCancel={() => setOpenMarkShippedModal(false)}
          onOk={(payload) => {
            const p = {
              ...payload,
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };
            setOpenMarkShippedModal(false);
            updateOrder(p);
          }}
        />
      )}
    </Container>
  );
};

OrderCard.propTypes = orderPropTypes;

OrderCard.defaultProps = orderDefaultProps;

export default OrderCard;
