import { useState } from "react";
import Drawer from "components/Header/Drawer";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "assets/images/logo-white.png";
import { Left } from "styles/components/header";

const LeftView = () => {
  const [drawer, setDrawer] = useState(false);
  return (
    <>
      <Left>
        <MenuOutlined onClick={() => setDrawer(true)} />
        <img src={Logo} alt="logo" />
      </Left>

      {drawer && <Drawer open={drawer} hide={() => setDrawer(false)} />}
    </>
  );
};

export default LeftView;
