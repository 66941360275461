import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Drawer as AntdDrawer } from "antd";
import MENUS from "components/Header/data";
import Logo from "assets/images/logo.png";
import {
  DrawerLogoWrapper,
  StyledLogo,
  StyledLink,
  MenuIcon,
  StyledFooter,
} from "styles/components/header";

const Drawer = ({ open, hide }) => {
  const user = useSelector((state) => state?.user);

  return (
    <AntdDrawer
      title={
        <DrawerLogoWrapper>
          <StyledLogo src={Logo} alt="logo" />
        </DrawerLogoWrapper>
      }
      placement="left"
      closable={false}
      onClose={hide}
      open={open}
      key="left"
      width={250}
      bodyStyle={{ padding: "12px" }}
    >
      {MENUS?.map(({ title, Icon, link }) => (
        <StyledLink to={link} key={title + Math.random()} onClick={hide}>
          <MenuIcon>
            <Icon />
          </MenuIcon>

          <div>{title}</div>
        </StyledLink>
      ))}

      <StyledFooter>
        <p>{user?.fullName}</p>
        <p className="role-name">({user?.roleName})</p>
      </StyledFooter>
    </AntdDrawer>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool,
  hide: PropTypes.func,
};

Drawer.defaultProps = {
  open: false,
  hide: () => {},
};

export default Drawer;
