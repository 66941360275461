import moment from "moment";
import { Button, Empty } from "antd";
import DatePicker from "components/DatePicker";
import Input from "components/Input";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  Wrapper,
  Title,
  Footer,
  Row,
  TitleWrapper,
  StyledPlusCircleOutlined,
  StyledDeleteOutlined,
} from "styles/pages/order";

const CompletedDates = ({ state, setState, updateOrder }) => {
  // const disabled =
  // state?.decorations?.filter(
  //   (d) => !state?.[`${d?.title?.toLowerCase()}InProductionDate`]
  // )?.length ||
  // !state?.shippingParcelInformation?.length ||
  // !!state?.shippingParcelInformation?.find(
  //   (s) => !s?.length || !s?.width || !s?.height || !s?.weight
  // );

  const disabled = state?.decorations?.filter(
    (d) => !state?.[`${d?.title?.toLowerCase()}CompletedDate`]
  )?.length;

  // const isInProduction = state?.status === "In-production";
  const completed = state?.status === "Completed";
  const isReady = state?.status === "Ready";

  const handleShippingParcelInformation = (key, value, i) =>
    setState({
      ...state,
      shippingParcelInformation: state?.shippingParcelInformation?.map(
        (t, j) => {
          if (i !== j) {
            return t;
          }

          return {
            ...t,
            [key]: value,
          };
        }
      ),
    });

  return (
    <Wrapper>
      <div>
        <Title>Provide Completed Date(s)</Title>
        <Row>
          {state?.decorations?.map((d) => {
            const key = `${d?.title?.toLowerCase()}CompletedDate`;

            return (
              <DatePicker
                disabled={!isReady}
                key={d?.title}
                label={`${d?.title} CompletedDate`}
                format="DD/MM/YYYY"
                defaultValue={state[key] ? moment.unix(state[key]) : null}
                onChange={(_, dateString) => {
                  setState({
                    ...state,
                    [key]: moment(dateString, "DD/MM/YYYY").unix(),
                  });
                }}
              />
            );
          })}
        </Row>
      </div>

      <div style={{ display: "none" }}>
        <TitleWrapper>
          <Title>Shipping Parcel Information</Title>
          {!completed && (
            <StyledPlusCircleOutlined
              onClick={() =>
                setState({
                  ...state,
                  shippingParcelInformation: !state.shippingParcelInformation
                    ? [{ parcelNumber: 1 }]
                    : [
                        ...state.shippingParcelInformation,
                        {
                          parcelNumber:
                            state.shippingParcelInformation.length + 1,
                        },
                      ],
                })
              }
            />
          )}
        </TitleWrapper>

        {!state?.shippingParcelInformation && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        {state?.shippingParcelInformation?.map((s, i) => (
          <Row key={s?.parcelNumber}>
            <Input label="Parcel Number" value={s?.parcelNumber} disabled />
            <Input
              label="Length (inches)"
              value={s?.length}
              type="Number"
              disabled={completed}
              onChange={(e) =>
                handleShippingParcelInformation(
                  "length",
                  Number(e?.target?.value),
                  i
                )
              }
            />
            <Input
              label="Width (inches)"
              type="Number"
              disabled={completed}
              value={s?.width}
              onChange={(e) =>
                handleShippingParcelInformation(
                  "width",
                  Number(e?.target?.value),
                  i
                )
              }
            />
            <Input
              label="Height (inches)"
              type="Number"
              disabled={completed}
              value={s?.height}
              onChange={(e) =>
                handleShippingParcelInformation(
                  "height",
                  Number(e?.target?.value),
                  i
                )
              }
            />
            <Input
              label="Weight (.lbs)"
              type="Number"
              value={s?.weight}
              disabled={completed}
              onChange={(e) =>
                handleShippingParcelInformation(
                  "weight",
                  Number(e?.target?.value),
                  i
                )
              }
            />

            {!completed && (
              <StyledDeleteOutlined
                onClick={() =>
                  setState({
                    ...state,
                    shippingParcelInformation:
                      state?.shippingParcelInformation?.filter(
                        (_, j) => i !== j
                      ),
                  })
                }
              />
            )}
          </Row>
        ))}
      </div>

      {isReady && (
        <Footer>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              const payload = {
                status: "Completed",
                vendorName: state?.vendorName,
                orderNumber: state?.orderNumber,
                notifyClients: true,
                shippingParcelInformation: state?.shippingParcelInformation,
              };

              for (let i = 0; i < state?.decorations?.length; i += 1) {
                const element = state?.decorations[i];
                const inProductionKey = `${element?.title?.toLowerCase()}CompletedDate`;
                payload[inProductionKey] = state[inProductionKey];

                if (!state[inProductionKey]) {
                  payload.status = "In-production";
                }
              }

              updateOrder(payload);
            }}
          >
            Save
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

CompletedDates.propTypes = PROP_TYPES;

CompletedDates.defaultProps = DEFAULT_STATE;

export default CompletedDates;
