import { useMutation } from "react-query";
import HttpService from "services/http";

export const post = async ({
  url,
  payload,
  isPut = false,
  isPatch = false,
}) => {
  try {
    let response;

    if (isPut) {
      response = await HttpService.put(url, payload);
    } else if (isPatch) {
      response = await HttpService.patch(url, payload);
    } else {
      response = await HttpService.post(url, payload);
    }

    return response;
  } catch (error) {
    return { error: error?.message };
  }
};

export const usePost = () => useMutation(post);
