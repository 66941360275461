import PropTypes from "prop-types";

export const propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    roleName: PropTypes.string,
    password: PropTypes.string,
    handleStateChange: PropTypes.func,
  }),
};

export const defaultProps = {};
