export const BOT_USA_ADDRESS = {
  companyLogoUrl:
    "https://bigoventees.netlify.app/static/media/logo.f7aedac0.png",
  comapnyName: "Big Oven Tees LLC",
  addressLineOne: "8742 Buffalo Ave",
  city: "Niagara Falls",
  state: "NY",
  postalCode: "14304",
  country: "US",
  email: "evelinan@eurotex.com",
};

export const BOT_CA_ADDRESS = {
  companyLogoUrl:
    "https://bigoventees.netlify.app/static/media/logo.f7aedac0.png",
  comapnyName: "Big Oven Tees INC",
  addressLineOne: "122 Middleton Street",
  city: "Brantford",
  state: "ON",
  postalCode: "N3S7V7",
  country: "CA",
  email: "customerservice@bigoventees.com",
};
