import styled from "styled-components";
import { Breadcrumb as AntdBreadcrumb, Timeline } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { PDFViewer } from "@react-pdf/renderer";

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
`;

export const Wrapper = styled.div`
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 150px 2fr 1fr;
  gap: 20px;
`;

export const Logo = styled.img`
  width: 100%;
`;

export const Pdfs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const StyledOrderNumber = styled.div`
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledArtFiles = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledBreadcrumb = styled(AntdBreadcrumb)`
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  a {
    font-weight: normal;
    text-decoration: underline;
    :hover {
      background-color: transparent;
    }
  }
`;

export const Chip = styled.div`
  display: flex;
  gap: 5px;
  border: 1px solid black;
  border-radius: 50px;
  padding: 5px 15px;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
`;

export const StyledTimeline = styled(Timeline)``;

export const StyledTimelineItem = styled(Timeline.Item)`
  &.ant-timeline-item {
    margin-left: 10px;
    margin-top: 24px;
  }
`;

export const StyledEvent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`;

export const EventInfo = styled.div``;

export const EventTime = styled.h5`
  margin: 0;
  color: #999999;
`;

export const StyledEventName = styled.h3`
  margin: 0;
`;

export const StyledEventDes = styled.h5`
  margin: 0;
  color: #999999;
`;

export const StyledPDFViewer = styled(PDFViewer)`
  width: 100%;
  height: 800px;
`;

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 800px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  button.ant-btn {
    background-color: black;
    color: white;
    :disabled {
      opacity: 0.5;
    }
    :hover {
      background-color: black;
    }
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 17px;
  cursor: pointer;
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  cursor: pointer;
  color: red;
  font-size: 20px;
`;

export const StyledCloudDownloadOutlined = styled(CloudDownloadOutlined)`
  cursor: pointer;
`;
