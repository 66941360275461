import styled from "styled-components";
import { Alert } from "antd";

export const Container = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 1000px;
`;

export const StyledFilter = styled.div`
  margin: 20px 0 10px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const OrdersGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
`;
