import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTabIndex: 0,
  page: 1,
};

export const ordersTabsSlice = createSlice({
  name: "ordersTab",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.selectedTabIndex = action?.payload?.selectedTabIndex;
      state.page = action?.payload?.page;
    },
    clearTab: (state) => {
      state.selectedTabIndex = undefined;
      state.page = undefined;
    },
  },
});

export const { setTab, clearTab } = ordersTabsSlice.actions;

export default ordersTabsSlice.reducer;
