import { Link } from "react-router-dom";
import { ORDERS } from "constants/routes";
import { StyledBreadcrumb } from "styles/pages/order";

const { Item } = StyledBreadcrumb;

const Breadcrumb = () => (
  <StyledBreadcrumb>
    <Item>
      <Link to={ORDERS}>Orders</Link>
    </Item>
    <Item>Order</Item>
  </StyledBreadcrumb>
);

export default Breadcrumb;
