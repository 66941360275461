import moment from "moment";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { BOT_CA_ADDRESS, BOT_USA_ADDRESS } from "constants/address";
import LOGO_BASE64 from "pages/order/WorkOrderPdf/logo";
import styles from "pages/order/WorkOrderPdf/styles";

const {
  page,
  header,
  subTitle,
  title,
  shipToWrapper,
  row,
  tableHead,
  tableHeadText,
  tableBody,
  tableBodyText,
  heading,
  image,
  tableHeadDescriptionText,
  tableBodyDescriptionText,
  addiotnalInformationText,
  footer,
  devider,
} = styles;

const WorkOrderPdf = ({ state }) => {
  const printProvider = state?.printProvider?.id;
  const address = printProvider === "CA" ? BOT_CA_ADDRESS : BOT_USA_ADDRESS;
  const decoratorData = state?.decoratorData;
  const shipAddress = decoratorData?.shipAddress;
  const orderEvents = state?.orderEvents;
  const orderSubmitted = orderEvents?.find((o) =>
    o?.event?.includes("Work Order Submitted")
  );
  const lineItems = decoratorData?.lineItems;
  const otherCharges = decoratorData?.otherCharges;

  const orderSubmittedDate = orderSubmitted
    ? moment.unix(orderSubmitted?.timeStamp).format("MMM DD, YYYY")
    : "";

  if (!state?.decoratorData) return <Document />;

  const getTotalAmount = () => {
    let totalAmount = 0;

    for (let i = 0; i < lineItems.length; i += 1) {
      totalAmount += lineItems[i]?.chargeAmount || 0;
    }
    for (let i = 0; i < otherCharges.length; i += 1) {
      totalAmount += otherCharges[i]?.chargeAmount || 0;
    }

    return totalAmount;
  };

  return (
    <Document>
      <Page style={page}>
        <View style={header}>
          <View>
            <Text style={title}>{address?.comapnyName}</Text>
            <Text style={subTitle}>{address?.addressLineOne}</Text>
            <Text style={subTitle}>
              {address?.city} {address?.state} {address?.postalCode}
            </Text>
            <Text style={subTitle}>{address?.country}</Text>
            <Text style={subTitle}>{address?.email}</Text>
          </View>
          <View>
            <Image style={image} src={LOGO_BASE64} />
          </View>
        </View>

        <Text style={heading}>Work Order</Text>

        <View style={shipToWrapper}>
          <View style={row}>
            <Text style={title}>Ship To</Text>
            <Text style={title}>Submitted Date</Text>
          </View>

          <View style={row}>
            <Text style={subTitle}>{shipAddress?.shipName}</Text>
            <Text style={subTitle}>{orderSubmittedDate}</Text>
          </View>

          <Text style={subTitle}>
            {shipAddress?.address1} {shipAddress?.address2}
          </Text>
          <Text style={subTitle}>
            {shipAddress?.city} {shipAddress?.state} {shipAddress?.zip}
          </Text>
          <Text style={subTitle}>{shipAddress?.country}</Text>
        </View>

        <View style={devider} />

        <View style={shipToWrapper}>
          <View style={row}>
            <Text style={title}>Garment Sent Date</Text>
            <Text style={title}>Tracking Number</Text>
            <Text style={title}>Order Due Date</Text>
          </View>

          <View style={row}>
            <Text style={subTitle}>
              {decoratorData?.garmentSentDate
                ? moment
                    .unix(decoratorData?.garmentSentDate)
                    .format("MMM DD, YYYY")
                : "NA"}
            </Text>
            <Text style={subTitle}>
              {decoratorData?.trackingNumber || "NA"}
            </Text>
            <Text style={subTitle}>
              {decoratorData?.orderDueDate
                ? moment
                    .unix(decoratorData?.orderDueDate)
                    .format("MMM DD, YYYY")
                : "NA"}
            </Text>
          </View>
        </View>

        <View style={tableHead}>
          <Text style={tableHeadDescriptionText}>Description</Text>
          <Text style={tableHeadText}>Quantity</Text>
          <Text style={tableHeadText}>Color</Text>
          <Text style={tableHeadText}>Size</Text>
          <Text style={tableHeadText}>Print Type</Text>
          <Text style={tableHeadText}>Deco Cost</Text>
          <Text style={tableHeadText}>Charge Amount</Text>
        </View>

        {lineItems?.map((l, i) => {
          const key = `lineItems${i}`;
          return (
            <View key={key}>
              <View style={tableBody}>
                <Text style={tableBodyDescriptionText}>{l?.description}</Text>
                <Text style={tableBodyText}>{l?.qty}</Text>
                <Text style={tableBodyText}>{l?.color}</Text>
                <Text style={tableBodyText}>{l?.size}</Text>
                <Text style={tableBodyText}>{l?.decoration?.label}</Text>
                <Text style={tableBodyText}>{l?.decorationCost}</Text>
                <Text style={tableBodyText}>{l?.chargeAmount}</Text>
              </View>
              <View style={tableBody}>
                <Text style={addiotnalInformationText}>
                  {l?.additionalInformation}
                </Text>
              </View>
            </View>
          );
        })}

        {otherCharges?.map((l, i) => {
          const key = `otherCharges${i}`;
          return (
            <View style={tableBody} key={key}>
              <Text style={tableBodyDescriptionText}>{l?.description}</Text>
              <Text style={tableBodyText}>{l?.qty}</Text>
              <Text style={tableBodyText} />
              <Text style={tableBodyText} />
              <Text style={tableBodyText} />
              <Text style={tableBodyText}>{l?.unitCost}</Text>
              <Text style={tableBodyText}>{l?.chargeAmount}</Text>
            </View>
          );
        })}

        <View style={styles.totalCostWrapper}>
          <Text style={styles.totalCost}>
            Total Amount ({printProvider === "CA" ? "CAD" : "USD"}) :
          </Text>
          <Text>{getTotalAmount()}</Text>
        </View>

        <Text style={footer}>
          Please email us at customerservice@bigoventees.com if you have any
          questions or concerns.
        </Text>
      </Page>
    </Document>
  );
};

WorkOrderPdf.propTypes = PROP_TYPES;

WorkOrderPdf.defaultProps = DEFAULT_STATE;

export default WorkOrderPdf;
