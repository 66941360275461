import PropTypes from "prop-types";
import { Input as AntdInput } from "antd";
import { Container, Label } from "styles/components/Input";

const Input = ({ label, ...rest }) => (
  <Container>
    {!!label && <Label>{label}</Label>}
    <AntdInput size="large" {...rest} />
  </Container>
);

Input.propTypes = {
  label: PropTypes.string,
};

Input.defaultProps = { label: "" };

export default Input;
