import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumb from "pages/order/OrderBreadcrumb";
import ArtFiles from "pages/order/ArtFiles";
import WorkOrder from "pages/order/WorkOrder";
import ArtProof from "pages/order/ArtProof";
import OrderInformation from "pages/order/OrderInformation";
// import Events from "pages/order/Events";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import { APP_VERSION, ORDERS } from "constants/services";
import { Container, Pdfs } from "styles/pages/order";

const OrderPage = () => {
  const { mutateAsync } = usePost();
  const [state, setState] = useState();
  const { orderNumber, vendorName } = useParams();
  const dispatch = useDispatch();
  const appVersion = useSelector((s) => s?.appVersion?.version);
  const { refetch: appVersionRefetch } = useFetch(APP_VERSION, APP_VERSION, {
    enabled: false,
  });

  const { refetch: refetchOrder } = useFetch(
    ORDERS,
    `${ORDERS}?orderNumber=${orderNumber}&vendorName=${vendorName}`,
    {
      enabled: false,
    }
  );

  const initiateState = async (data) => {
    if (data) {
      const p = {
        ...state,
        ...data,
        vendor: { id: data?.vendorName, label: data?.vendorName },
        vendorNameInput: data?.vendorName,
        printProvider: { id: data?.printProvider, label: data?.printProvider },
        printProviderInput: data?.printProvider,
        poNumber: orderNumber,
        assigneeEmails: data?.assigneeEmails?.map((c) => ({
          title: c,
          value: c,
        })),
        clientEmails: data?.clientEmails?.map((c) => ({
          title: c,
          value: c,
        })),
        decorations: data?.decorations?.map((c) => ({
          title: c,
          value: c,
        })),
      };

      for (let i = 0; i < data?.decorations?.length; i += 1) {
        const title = data?.decorations[i];
        const completedDateKey = `${title?.toLowerCase()}CompletedDate`;
        const inProductionDisableKey = `${title?.toLowerCase()}DisableInProductionDate`;
        p[inProductionDisableKey] = !!data[completedDateKey];
      }

      setState(p);
    }
  };

  const fetchOrder = async () => {
    const { data } = await refetchOrder();
    initiateState(data);
  };

  const fetchOrderData = () => {
    if (orderNumber && vendorName) {
      fetchOrder();
    }
  };

  const compareVersion = async () => {
    const { data } = await appVersionRefetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    } else {
      fetchOrderData();
    }
  };

  const updateOrder = async (payload) => {
    const { data } = await mutateAsync({
      url: ORDERS,
      isPatch: true,
      payload,
    });

    initiateState(data);
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  // useEffect(() => {
  //   fetchOrderData();
  // }, [orderNumber, vendorName]);

  return (
    <Container>
      <Breadcrumb />
      <OrderInformation
        state={state}
        setState={setState}
        updateOrder={updateOrder}
      />
      <ArtFiles state={state} />
      <Pdfs>
        <WorkOrder state={state} />
        <ArtProof state={state} />
      </Pdfs>

      {/* <Events state={state} /> */}
    </Container>
  );
};

export default OrderPage;
