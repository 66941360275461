import { Empty, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  Wrapper,
  StyledArtFiles,
  Chip,
  Title,
  StyledCloudDownloadOutlined,
} from "styles/pages/order";

const ArtFiles = ({ state }) => {
  const artFiles = state?.artfiles;

  return (
    <Wrapper>
      <Title>
        Art Files
        <Tooltip title="Download Artfiles" placement="top">
          <StyledCloudDownloadOutlined
            onClick={() => {
              for (let index = 0; index < artFiles.length; index += 1) {
                const element = artFiles[index];
                const secureUrl = element?.secureUrl;
                const splitValue = secureUrl.split("/upload/");
                const url = `${splitValue[0]}/upload/fl_attachment:${
                  state?.orderNumber
                }_${element?.fileName.split(".")[0]}/${splitValue[1]}`;
                window.open(url);
              }
            }}
          />
        </Tooltip>
      </Title>
      {artFiles?.length > 0 ? (
        <StyledArtFiles>
          {artFiles?.map((a) => (
            <Chip key={a?.fileName} onClick={() => window.open(a?.secureUrl)}>
              <FileOutlined />
              {a?.fileName}
            </Chip>
          ))}
        </StyledArtFiles>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Wrapper>
  );
};

ArtFiles.propTypes = PROP_TYPES;

ArtFiles.defaultProps = DEFAULT_STATE;

export default ArtFiles;
