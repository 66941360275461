import Input from "components/Input";
import Select from "components/Select";
import { propTypes, defaultProps } from "components/SignUpModal/types";
import { Section, Title, Row } from "styles/components/sign-up-modal";

const CompanyInformation = ({ state, handleStateChange, setState }) => (
  <Section>
    <Title>Company Information</Title>
    <Row>
      <Input
        label="Company Name"
        value={state?.companyName}
        onChange={(e) => handleStateChange("companyName", e?.target?.value)}
      />
      <Input
        label="Company Website URL"
        value={state?.companyWebsiteUrl}
        onChange={(e) =>
          handleStateChange("companyWebsiteUrl", e?.target?.value)
        }
      />
    </Row>

    <Row>
      <Input
        label="Phone Number"
        type="number"
        value={state?.phoneNumber}
        onChange={(e) => handleStateChange("phoneNumber", e?.target?.value)}
      />
      <Input
        label="Tax Number"
        value={state?.taxNumber}
        onChange={(e) => handleStateChange("taxNumber", e?.target?.value)}
      />
    </Row>

    <Row>
      <Input
        label="Address1"
        value={state?.address1}
        onChange={(e) => handleStateChange("address1", e?.target?.value)}
      />
      <Input
        label="Address2"
        value={state?.address2}
        onChange={(e) => handleStateChange("address2", e?.target?.value)}
      />
    </Row>

    <Row>
      <Select
        disabled={!state?.countries}
        size="large"
        label="Country"
        value={state?.country}
        options={state?.countries?.map((o) => ({
          value: o?.name,
          label: o?.name,
        }))}
        onChange={(v) => setState({ ...state, country: v, state: undefined })}
      />
      <Select
        disabled={!state?.country}
        size="large"
        label="State"
        value={state?.state}
        options={state?.countries
          ?.find((c) => c?.name === state?.country)
          ?.states?.map((o) => ({
            value: o?.name,
            label: o?.name,
          }))}
        onChange={(v) => handleStateChange("state", v)}
      />
    </Row>

    <Row>
      <Input
        label="City"
        value={state?.city}
        onChange={(e) => handleStateChange("city", e?.target?.value)}
      />
      <Input
        label="Zip"
        value={state?.zip}
        onChange={(e) => handleStateChange("zip", e?.target?.value)}
      />
    </Row>
  </Section>
);

CompanyInformation.propTypes = propTypes;

CompanyInformation.defaultProps = defaultProps;

export default CompanyInformation;
